import React from 'react';
import { Modal } from 'antd';


const ExcelErrors = ({ errMsgs }) => {
    const showError = () => {
        const errTitle = "Excel Data Validation Error";

        const finalErrMsgs = errMsgs || [""];


        Modal.error({
            className: 'info-modal',
            title: (<h3>{errTitle}</h3>),
            content:
                (<>
                    <ul>
                        {finalErrMsgs?.map((item) => (<li className='info-modal-li'>{item}</li>))}
                    </ul>
                </>),
            okButtonProps: { "className": "modal-btn" },
            onOk: () => {
                Modal.destroyAll();
            }
        });
    };

    return (
        <>
            {showError()}
        </>
    )
}

export default ExcelErrors;