// This is AR Excel Upload Section

import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Progress, Spin, Table, Tooltip } from 'antd'
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faXmark } from '@fortawesome/free-solid-svg-icons'
import Heading from '../HOC/Heading'
import { createCrossChargerequest } from '../../graphql/mutations';
import {
    addPaddingToNum,
    arPostingKeys,
    formatDate,
    checkAndPadd,
    apPostingKeys,
    Processed,
    Failed,
    Updated,
    cancelTimerInterval,
    currWithZeroDecimals,
    excelWrongFormatErr,
    excelWrongFormatErrSol,
    getRequestType,
    invalidReqTypeErrL1,
    invalidReqTypeErrSol,
    incompleteDetErrSol1,
    incompleteDetErrL1,
    currNoDecimalsErrSol,
    amtFormattedErrSol,
    amtZeroErrSol,
    amtMoreThan2DecErrSol,
    reqNoErr,
    amtFormattedErr,
    amtZeroErr,
    amtMoreThan2DecErr
} from '../helper';
import ContextData from '../context/ContextData';
import { API } from 'aws-amplify';
import HorizontalLine from '../HOC/HorizontalLine';
import { InfoCircleOutlined } from '@ant-design/icons';

import '../../css/excelUpload.css'
import { getCommXchargeDCsks, getCommXchargeDKNA1, getCommXchargeDLFA1, getCommXchargeDS4Costcenter, getCommXchargeDS4Customer, getCommXchargeDS4Supplier, getCrossChargeForm, listCommXchargeDS4Customers, listCommXchargeDS4Suppliers } from '../../graphql/queries';
import { getExcelProcessColumns } from '../columns';

import ExcelErrors from './ExcelErrors';
import { getSAPType } from '../../utils/utils';

const ExcelUpload = () => {
    let tempErrMsgs = [];

    const [fileName, setFileName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [percentCmplt, setPercentCmplt] = useState(0);
    const [processedData, setProcessedData] = useState(null);
    const [inProcess, setInProcess] = useState(true);
    const [showSpin, setShowSpin] = useState(false);

    const [hasValidData, setHasValidData] = useState(true);
    const [errMsgs, setErrMsgs] = useState(null);

    const contextValue = useContext(ContextData);
    const { currentUser, timerCtxt } = contextValue;

    // console.log("processedData", processedData);

    const excelTableColumns = getExcelProcessColumns();

    // to clear all the leftout timeouts from a component
    useEffect(() => {
        const timerCancel = setInterval(() => {
            const { timerCtxt } = contextValue;
            for (let j = 0; j < timerCtxt.length; j++) {
                clearTimeout(timerCtxt[j])
            }
        }, cancelTimerInterval)

        return () => clearInterval(timerCancel)

    }, [timerCtxt])

    const handleExcelUpload = (e) => {

        const file_Name = e.target.files[0] && e.target.files[0].name;
        const fileToUpload = e.target.files[0];
        const fileExtension = file_Name?.split(".")?.pop();

        if (['xlsx'].includes(fileExtension.toLowerCase())) {
            setFileName(file_Name);
            setSelectedFile(fileToUpload);
            setInProcess(false);
        } else {
            Modal.error({
                className: 'info-modal',
                title: `Please upload file of type xlsx`,
                okButtonProps: { "className": "modal-btn" }
            });
        }


    }

    const groupBy = (arr, key) => {
        return arr.reduce((acc, cur) => {
            if (cur[key]) {
                acc[cur[key]] = [...acc[cur[key]] || [], cur];
            }
            return acc;

        }, {});
    }

    const groupByKeyValues = (arr, key) => {
        const a = groupBy(arr, key)
        return Object.values(a)
    }

    const hasAtMostTwoDecimals = (amount) => {
        // RegEx Patter to check if num have more than 2 decimals or not
        const regExMatch = amount.toString().match(/^(\d*\.{0,1}\d{0,2}$)/);

        // The match() returns null if the string gets NO match in the RegEx
        return (regExMatch === null) ? false : true;
    }

    const hasCurrencyFomatting = (amount) => {
        const amountStr = amount.toString();

        const decimalsCount = amountStr.split(".").length - 1;

        return (amountStr.includes(" ")
            ||
            amountStr.includes(",")
            ||
            decimalsCount > 1);
    }

    const canCurrHaveDecimals = (currency, amount) => {
        const currencyStr = currency.toString();


        const hasDecimals = amount % 1 !== 0;

        const isDecimalAllowed = !currWithZeroDecimals.includes(currencyStr);



        return (hasDecimals ? isDecimalAllowed : true);
    }

    // After "Process Btn" is clicked this method is called
    const processExcelData = () => {

        setInProcess(true);
        setShowSpin(true);

        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        const masterData = [];
        fileReader.onload = async (event) => {
            const data = event.target.result;
            const workBook = XLSX.read(data, { type: "binary" });

            // console.log("workBook", workBook)
            // console.log("XLSX.utils", XLSX.utils)

            const sheet1 = workBook.SheetNames[0]

            // workBook.SheetNames.forEach(async (sheet) => {
            const rowObject = XLSX.utils.sheet_to_row_object_array(workBook.Sheets[sheet1]);
            const rowObject_JSON = XLSX.utils.sheet_to_json(workBook.Sheets[sheet1]);
            // console.log("rowObject", rowObject)
            // console.log("rowObject_JSON", rowObject_JSON)

            // This is ARRAY of ARRAY of each Row from the Excel Sheet
            const groupedData = groupByKeyValues(rowObject, "Request_No");

            // console.log("groupedData", groupedData);

            // console.log("groupData", groupedData[0]);

            // If excel file is in wrong format
            if (groupedData?.length === 0) {
                tempErrMsgs.push(excelWrongFormatErr);
                tempErrMsgs.push(excelWrongFormatErrSol);

                setHasValidData(false);
                setErrMsgs(tempErrMsgs);
                setInProcess(false);
                setShowSpin(false);

                console.warn("Excel Error: ", tempErrMsgs, groupedData);
            }

            // Cheking few validations of amount in here
            for (let i = 0; i < groupedData?.length; i++) {
                const requestNo = groupedData[i][0]?.Request_No;

                const requestType = getRequestType(groupedData[i][0]?.Request_Type);

                if (!requestType) {
                    tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                    tempErrMsgs.push(`${invalidReqTypeErrL1} (${groupedData[i][0]?.Request_Type})`);
                    tempErrMsgs.push(invalidReqTypeErrSol);

                    setHasValidData(false);
                    setErrMsgs(tempErrMsgs);
                    setInProcess(false);
                    setShowSpin(false);

                    console.warn("Excel Error:", tempErrMsgs, "-", groupedData[i][0]?.Request_Type)

                    break;
                }




                let errFlag = false;

                // Checking if any request (row) is blank
                for (let item of groupedData[i]) {
                    if (!item?.Item_Type) {
                        errFlag = true;

                        break;
                    }
                }

                if (errFlag) {
                    tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                    tempErrMsgs.push(incompleteDetErrL1);
                    tempErrMsgs.push(incompleteDetErrSol1);

                    setHasValidData(false);
                    setErrMsgs(tempErrMsgs);
                    setInProcess(false);
                    setShowSpin(false);

                    console.warn("Excel Error:", tempErrMsgs);

                    break;
                }

                



                // If error free
                const singleReq = {
                    "arLineItems": [],
                    "apLineItems": []
                };


                singleReq["requestType"] = requestType
                singleReq["requestDescription"] = groupedData[i][0]?.Request_Description?.trim()?.slice(0, 25)
                singleReq["sendingCompanyCode"] = groupedData[i][0]?.Sending_Company_Code
                singleReq["receivingCompanyCode"] = groupedData[i][0]?.Receiving_Company_Code
                singleReq["apRequestor"] = groupedData[i][0]?.AP_Requestor?.trim()?.toUpperCase()
                singleReq["currency"] = groupedData[i][0]?.Currency?.trim()?.toUpperCase()
                singleReq["exchangeRate"] = groupedData[i][0]?.Exchange_Rate
                singleReq["reqNo"] = groupedData[i][0]?.Request_No

                const nestedGroup = groupBy(groupedData[i], "Item_Type");



                //inserting AR line items
                for (let j = 0; j < nestedGroup?.AR?.length; j++) {
                    // If Currency selected cannot have deicmal values
                    if (j === 0 && !canCurrHaveDecimals(nestedGroup?.AR[j]?.Currency?.trim()?.toUpperCase(), nestedGroup?.AR[j]?.Amount)) {
                        setHasValidData(false);
                        setShowSpin(false);

                        const err = `The selected currency i.e. ${nestedGroup?.AR[j]?.Currency?.trim()?.toUpperCase()}, can't have decimal values - (${nestedGroup?.AR[j]?.Amount})`;

                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(err);
                        tempErrMsgs.push(currNoDecimalsErrSol);

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }

                    // If amount is formatted with commas and dots
                    if (hasCurrencyFomatting(nestedGroup?.AR[j]?.Amount)) {
                        setHasValidData(false);
                        setShowSpin(false);
                        

                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtFormattedErr} - (${nestedGroup?.AR[j]?.Amount})`);
                        tempErrMsgs.push(amtFormattedErrSol);

                        setErrMsgs(tempErrMsgs)

                        console.warn(tempErrMsgs);

                        break;
                    }

                    // If amount is 0
                    if (nestedGroup?.AR[j]?.Amount === 0) {
                        setHasValidData(false);
                        setShowSpin(false);


                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtZeroErr} (${nestedGroup?.AR[j]?.Amount}).`);
                        tempErrMsgs.push(amtZeroErrSol);

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }

                    // Checking if amount has more than 2 decimal places
                    if (!hasAtMostTwoDecimals(parseFloat(nestedGroup?.AR[j]?.Amount))) {
                        setHasValidData(false);
                        setShowSpin(false);


                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtMoreThan2DecErr} - (${nestedGroup?.AR[j]?.Amount})`)
                        tempErrMsgs.push(amtMoreThan2DecErrSol);
                        

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }



                    // getting trading parner according to the acc entered in excel 
                    let tp = null;
                    if (arPostingKeys?.includes(nestedGroup?.AR[j]?.Posting_Key?.toString())) {
                        tp = await getTP(nestedGroup?.AR[j]?.Account, 'ar', groupedData[i][0]?.Sending_Company_Code, nestedGroup?.AR[j]?.Posting_Key)
                    }

                    // getting profit center and tax jurisdiction if cost center is entered 
                    let prctr = null;
                    let txjcd = null;
                    const txcode = nestedGroup?.AR[j]?.Tax_Code?.toString()?.trim();
                    if (!(arPostingKeys?.includes(nestedGroup?.AR[j]?.Posting_Key?.toString()))) {
                        const costcntr = nestedGroup?.AR[j]?.CCTR;
                        if (costcntr) {
                            const cctrData = await getPrctrTaxJurisdiction(costcntr, groupedData[i][0]?.Sending_Company_Code);
                            prctr = cctrData?.prctr;
                            //populating tax jurisdiction only if taxcode is entered
                            if (txcode) {
                                txjcd = cctrData?.txjcd;
                            }
                        }
                        else {
                            prctr = nestedGroup?.AR[j]?.PRCTR;
                            if (txcode) {
                                txjcd = nestedGroup?.AR[j]?.Tax_Jurisdiction;
                            }
                        }

                    }

                    singleReq["arLineItems"].push(
                        {
                            id: addPaddingToNum((j + 1), 3),
                            item: addPaddingToNum((j + 1), 3),
                            postingKey: nestedGroup?.AR[j]?.Posting_Key?.toString(),
                            account: nestedGroup?.AR[j]?.Account?.toString()?.trim() && checkAndPadd(nestedGroup?.AR[j]?.Account, 10),
                            amount: Math.round((nestedGroup?.AR[j]?.Amount + Number.EPSILON) * 100) / 100,
                            taxCode: nestedGroup?.AR[j]?.Tax_Code?.toString()?.trim(),
                            taxJurisdiction: txjcd?.toString()?.trim() && checkAndPadd(txjcd?.toString()?.trim(), 10),
                            cctr: nestedGroup?.AR[j]?.CCTR?.toString()?.trim() && checkAndPadd(nestedGroup?.AR[j]?.CCTR, 10),
                            prctr: prctr?.toString()?.trim() && checkAndPadd(prctr?.toString()?.trim(), 10),
                            orderNumber: nestedGroup?.AR[j]?.Order_No?.toString()?.trim(),
                            lineItemAssignment: nestedGroup?.AR[j]?.Line_Item_Assignment?.toString()?.trim()?.slice(0, 18),
                            lineItemText: nestedGroup?.AR[j]?.Line_Item_Text?.toString()?.trim()?.slice(0, 50),
                            emg: nestedGroup?.AR[j]?.Ext_Mat_Grp?.toString()?.trim(),
                            product: nestedGroup?.AR[j]?.Product?.toString()?.trim(),
                            qty: nestedGroup?.AR[j]?.Qty,
                            uom: nestedGroup?.AR[j]?.UOM,
                            tradingPartner: tp,
                            wbselement: nestedGroup?.AR[j]?.WBS_Element
                        })
                }

                //inserting AP line items
                for (let j = 0; j < nestedGroup?.AP?.length; j++) {

                    // If amount is formatted with commas and dots
                    if (hasCurrencyFomatting(nestedGroup?.AP[j]?.Amount)) {
                        setHasValidData(false);
                        setShowSpin(false);
                        
                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtFormattedErr} - (${nestedGroup?.AP[j]?.Amount})`);
                        tempErrMsgs.push(amtFormattedErrSol);

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }

                    // If amount is 0
                    if (nestedGroup?.AP[j]?.Amount === 0) {
                        setHasValidData(false);
                        setShowSpin(false);
                        
                        
                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtZeroErr} (${nestedGroup?.AP[j]?.Amount}).`);
                        tempErrMsgs.push(amtZeroErrSol);

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }

                    // Checking if amount has more than 2 decimal places
                    if (!hasAtMostTwoDecimals(parseFloat(nestedGroup?.AP[j]?.Amount))) {
                        setHasValidData(false);
                        setShowSpin(false);
                        
                        
                        tempErrMsgs.push(`${reqNoErr} ${requestNo}`);
                        tempErrMsgs.push(`${amtMoreThan2DecErr} - (${nestedGroup?.AP[j]?.Amount})`)
                        tempErrMsgs.push(amtMoreThan2DecErrSol);
                        

                        setErrMsgs(tempErrMsgs);

                        console.warn("Excel Error:", tempErrMsgs);

                        break;
                    }


                    // getting trading parner according to the acc entered in excel 
                    let tp = null;
                    if (apPostingKeys?.includes(nestedGroup?.AP[j]?.Posting_Key?.toString())) {
                        tp = await getTP(nestedGroup?.AP[j]?.Account, 'ap', groupedData[i][0]?.Receiving_Company_Code, nestedGroup?.AP[j]?.Posting_Key)
                    }

                    // getting profit center and tax jurisdiction if cost center is entered 
                    let prctr = null;
                    let txjcd = null;
                    const txcode = nestedGroup?.AP[j]?.Tax_Code?.toString()?.trim();
                    if (!(apPostingKeys?.includes(nestedGroup?.AP[j]?.Posting_Key?.toString()))) {
                        const costcntr = nestedGroup?.AP[j]?.CCTR;
                        if (costcntr) {
                            const cctrData = await getPrctrTaxJurisdiction(costcntr, groupedData[i][0]?.Receiving_Company_Code);
                            prctr = cctrData?.prctr;
                            //populating tax jurisdiction only if taxcode is entered
                            if (txcode) {
                                txjcd = cctrData?.txjcd;
                            }
                        } else {
                            prctr = nestedGroup?.AP[j]?.PRCTR;
                            if (txcode) {
                                txjcd = nestedGroup?.AP[j]?.Tax_Jurisdiction;
                            }
                        }

                    }

                    singleReq["apLineItems"].push({
                        id: addPaddingToNum((j + 1), 3),
                        item: addPaddingToNum((j + 1), 3),
                        postingKey: nestedGroup?.AP[j]?.Posting_Key?.toString(),
                        account: nestedGroup?.AP[j]?.Account?.toString()?.trim() && checkAndPadd(nestedGroup?.AP[j]?.Account, 10),
                        amount: Math.round((nestedGroup?.AP[j]?.Amount + Number.EPSILON) * 100) / 100,
                        taxCode: nestedGroup?.AP[j]?.Tax_Code?.toString()?.trim(),
                        taxJurisdiction: txjcd?.toString()?.trim() && checkAndPadd(txjcd?.toString()?.trim(), 10),
                        cctr: nestedGroup?.AP[j]?.CCTR?.toString()?.trim() && checkAndPadd(nestedGroup?.AP[j]?.CCTR, 10),
                        prctr: prctr?.toString()?.trim() && checkAndPadd(prctr?.toString()?.trim(), 10),
                        orderNumber: nestedGroup?.AP[j]?.Order_No?.toString()?.trim(),
                        lineItemAssignment: nestedGroup?.AP[j]?.Line_Item_Assignment?.toString()?.trim()?.slice(0, 18),
                        lineItemText: nestedGroup?.AP[j]?.Line_Item_Text?.toString()?.trim()?.slice(0, 50),
                        emg: nestedGroup?.AP[j]?.Ext_Mat_Grp?.toString()?.trim(),
                        product: nestedGroup?.AP[j]?.Product?.toString()?.trim(),
                        qty: nestedGroup?.AP[j]?.Qty,
                        uom: nestedGroup?.AP[j]?.UOM,
                        tradingPartner: tp,
                        wbselement: nestedGroup?.AP[j]?.WBS_Element
                    })
                }
                // console.log("singleReq", singleReq)
                masterData.push(singleReq);

            }


            // console.log("masterData", masterData);

            // Checking if any data in the excel sheet have errors or not.
            if ((masterData[0]?.apLineItems?.length + masterData[0]?.arLineItems?.length)
                ===
                groupedData[0]?.length) {

                createReqFromJson(masterData);
            }
        }
    }


    // Only called when posting key is NOT [40, 50]
    const getTP = async (acc, type, companyCode, postingKey) => {
        const isS4 = await getSAPType(companyCode) === "s4";


        let query;
        let queryName;

        let variablesParams = isS4 ? { id: companyCode + "-" + acc } : { id: checkAndPadd(acc, 10) };
        let fieldName = isS4 ? "tradingpartner" : "vbund";



        if (type === 'ar') {
            if (isS4) {
                query = getCommXchargeDS4Customer;
                queryName = "getCommXchargeDS4Customer";
            }
            else {
                query = getCommXchargeDKNA1;
                queryName = "getCommXchargeDKNA1";
            }
        }
        else if (type === 'ap') {
            if (isS4) {
                query = getCommXchargeDS4Supplier;
                queryName = "getCommXchargeDS4Supplier";
            }
            else {
                query = getCommXchargeDLFA1;
                queryName = "getCommXchargeDLFA1";
            }
        }


        const tp = await API
            .graphql({ query: query, variables: variablesParams })
            .then(response => {
                // console.log("trading partner response", response);
                const tp = response?.data[queryName][fieldName];

                return tp;

            })
            .catch(err => {
                console.log("err getting trading partner", err);
            });

        return tp;
    }

    const getPrctrTaxJurisdiction = async (cctr, companyCode) => {
        const isS4 = await getSAPType(companyCode) === "s4";

        let query = isS4 ? getCommXchargeDS4Costcenter : getCommXchargeDCsks;
        let queryName = isS4 ? "getCommXchargeDS4Costcenter" : "getCommXchargeDCsks";

        let variablesParams = isS4 ? { id: cctr } : { id: checkAndPadd(cctr, 10) };
        let fieldNamePC = isS4 ? "profitcenter" : "prctr";
        let fieldNameTJ = isS4 ? "taxjurisdiction" : "txjcd";


        const cctrData = await API
            .graphql({ query: query, variables: variablesParams })
            .then(response => {
                // console.log("cctr response", response);
                const cctrdata = response?.data[queryName];

                return {
                    prctr: cctrdata[fieldNamePC],
                    txjcd: cctrdata[fieldNameTJ]
                }

            }).catch(err => {
                console.log("err getting trading partner", err)
            })

        return cctrData
    }


    const creatSingleReq = async (singleData, reqNum, totalReq) => {
        const reqNo = singleData.reqNo

        delete singleData.reqNo; //deleting since graphql throws err if its not defined

        const createInput = {
            actionType: "create",
            id: '123',
            arRequestor: currentUser?.userID?.toUpperCase(),
            arRequestorEmailAddress: currentUser?.userEmail,
            requestDate: formatDate(),
            ...singleData
        }

        // const cmpltPercent = (reqNum / totalReq) * 100
        // console.log("cmpltPercent", cmpltPercent);
        // setPercentCmplt(cmpltPercent);

        const res = await API
            .graphql({ query: createCrossChargerequest, variables: { input: createInput } })
            .then(async (response) => {
                // console.log("create response", response?.data?.createCrossChargerequest?.id);

                const exeID = response?.data?.createCrossChargerequest?.id
                const reqData = await getFromData(exeID, 3)

                // console.log("reqData", reqData)
                const prcData = {
                    id: reqData?.id,
                    requestId: reqData?.requestId,
                    excelReqNo: reqNo,
                    status: Processed,
                    reason: reqData ? "" : "Created but failed to retrieve request ID"
                }
                setProcessedData(prev => {
                    return [...(prev ? prev : []), prcData]
                })
                return exeID

            }).catch((err) => {
                console.log("create err", err);
                const prcData = {
                    id: reqNo,
                    excelReqNo: reqNo,
                    status: Failed,
                    reason: "Server error"
                }
                setProcessedData(prev => {
                    return [...(prev ? prev : []), prcData]
                })
            })
        return res
    }

    // to get req id once created it also retry 3 times if it fails 
    const getFromData = async (formID, limitLeft) => {

        const callAPI = async (formID) => {

            function retrunDataFromTimeout() {
                // this will ensure that the data is returned then moved to 
                // next step of code (making setimeout synchronous)
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        limitLeft--
                        const data = await callAPI(formID);
                        if (data) {
                            resolve(data)
                        } else {
                            reject(null)
                        }

                    }, 3000)
                });
            }

            const formData = await API
                .graphql({ query: getCrossChargeForm, variables: { id: formID } })
                .then((response) => {
                    return response?.data?.getCrossChargeForm
                }).catch(async (err) => {
                    console.log("error in retriving form data", err)
                    return await retrunDataFromTimeout()
                })
            if (formData) return formData
        }

        if (limitLeft < 0) {
            return null
        }
        else {
            const formResponse = await callAPI(formID)
            return formResponse
        }
    }

    const createReqFromJson = async (jsonData) => {
        for (let i = 0; i < jsonData?.length; i++) {
            await creatSingleReq(jsonData[i], (i + 1), jsonData?.length);
            const cmpltPercent = ((i + 1) / jsonData?.length) * 100
            // console.log("cmpltPercent", cmpltPercent);
            setPercentCmplt(cmpltPercent.toFixed(2));
            // console.log("Test await")
        }
        // console.log("all done")
        setShowSpin(false);
    }

    const handleClear = () => {
        setFileName(null);
        setSelectedFile(null);
        setPercentCmplt(0);
        setProcessedData(null);
        setInProcess(false);

        setHasValidData(true);
        setErrMsgs(null);
    }

    return (
        <div className='cc-excel-upload-cnt'>
            {/* <Modal
                title={<Heading
                    heading={'Upload Excel File'}
                />}
                top
                open={props?.openModal}
                okText={'Process Requests'}
                cancelButtonProps={{ "className": "ortho-btn" }}
                okButtonProps={{ "className": "ortho-btn" }}
                onOk={processExcelData}
                onCancel={() => props.closeModal()}
                width={800}
            >
                <input type='file' id='excel-file' style={{ display: 'none' }}
                    onChange={handleExcelUpload}
                />
                <label
                    htmlFor='excel-file'
                    className='ortho-btn'
                >
                    <FontAwesomeIcon icon={faUpload} className='cc-icons' style={{ marginRight: "10px", color: "white", opacity: "1" }} />
                    Upload a file
                </label>
                <span style={{ marginLeft: "20px" }}>{fileName}</span>
            </Modal> */}

            <Heading
                heading={<>
                    Upload Excel Template (AR)
                    <Tooltip title={`Allowed file type is xlsx`}>

                        <InfoCircleOutlined
                            className='cc-icons'
                            style={{ marginLeft: "10px" }}
                        />
                    </Tooltip>
                </>}
            >
                {/* <div className='cc-link'>Download Template</div> */}
            </Heading>

            <HorizontalLine />

            {/* Buttons */}
            <div className='cc-upload-btn-cnt'>
                <div className='cc-upload-btn'>
                    {/* Upload Button */}
                    <input type='file' id='excel-file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' style={{ display: 'none' }}
                        onChange={handleExcelUpload}
                        onClick={(e) => { e.target.value = '' }}
                    />
                    <label
                        htmlFor='excel-file'
                        className={fileName ? 'disabled-btn upld-btn' : 'ortho-btn upld-btn'}>
                        {
                            !fileName &&
                            <FontAwesomeIcon icon={faUpload} className='cc-icons' style={{ marginRight: "10px", color: "white", opacity: "1" }} />
                        }
                        Upload a file
                    </label>

                    {/* Clear (X) Button */}
                    <div className='d-flex align-items-end'>
                        <span className='file-name-span'>{fileName}</span>
                        {
                            fileName &&
                            <FontAwesomeIcon icon={faXmark} onClick={handleClear} className='cc-icons  cc-icons-grey' style={{ marginLeft: "10px", paddingBottom: "3px" }} />
                        }
                    </div>
                </div>

                {/* Process Req Button */}
                <div>
                    <Button className='ortho-btn prc-btn' onClick={processExcelData} disabled={inProcess || !fileName}>
                        Process Requests
                    </Button>
                </div>
            </div>

            {/* Spin Logic */}
            {
                showSpin &&
                <div className='excel-spin'>
                    <Spin tip="Processing" size="small" />
                </div>
            }

            <Progress percent={percentCmplt} />

            {
                processedData?.length > 0 &&
                <Table
                    id="excel-table"
                    size="small"
                    className='excelTable'
                    style={{ width: "100%" }}
                    columns={excelTableColumns}
                    dataSource={processedData}
                    rowKey='excelReqNo'
                    bordered
                    // pagination={reviewData?.apLineItems?.length > 5 ? { pageSize: 5, size: 'small' } : false}  
                    pagination={false}
                    rowClassName={record => {
                        if ([Processed, Updated].includes(record.status)) {
                            return 'excel-success-row'
                        } else {
                            return 'excel-failed-row'
                        }
                    }}
                />
            }


            {/* Error Message in Excel Data */}
            {
                !hasValidData &&
                <>
                    <ExcelErrors errMsgs={errMsgs} />
                </>

            }

        </div>
    )
}

export default ExcelUpload